<template>
	<div class="lms-navbar"
		:class="{ 'sticky': scrollPosition > 200, 'sticky-mobile': scrollPosition > 200, 'open': showMenu }">
		<div class="lms-content-frame">
			<div class="logo-container">
				<router-link to="/">
					<img src="@/assets/logo_regular.svg" alt="lumos logo">
				</router-link>

				<button v-if="!noMenu" @click="showMenu = !showMenu" class="nav-link menu mobile">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
						<path
							d="M11,0A11,11,0,1,1,0,11,11,11,0,0,1,11,0Zm0,2a9,9,0,1,0,9,9A9,9,0,0,0,11,2Zm3.924,3.811a1,1,0,0,1,1.265,1.265h0l-2.12,6.36a1,1,0,0,1-.632.632h0l-6.36,2.12a1,1,0,0,1-1.265-1.265h0l2.12-6.36a1,1,0,0,1,.632-.632h0Zm-1.266,2.53L9.67,9.67,8.341,13.659l3.988-1.33Z" />
					</svg>
				</button>
			</div>

			<div class="button-container">

<!--				<router-link tag="a" class="nav-link closer" v-for="link in navSettings" :key="link.title"-->
<!--					:to="{ path: link.path, params: {} }" @click="showMenu = false" v-scroll-to="link.scrollSettings"-->
<!--					v-if="link.title !== 'Blog'">-->
<!--					<span v-html="link.icon"></span>-->
<!--					<p>{{ link.title }}</p>-->
<!--				</router-link>-->
				<!-- Uncomment the upper code snippet above and comment out the two following sections if you want to go back to the non-pulsing Apply button and vice versa-->
				<router-link tag="a" class="nav-link closer" v-for="link in navSettings" :key="link.title"
					:to="{ path: link.path, params: {} }" @click="showMenu = false" v-scroll-to="link.scrollSettings"
					v-if="link.title !== 'Blog' && link.title !== 'Apply' && link.title !== 'Bewerben'">
					<span v-html="link.icon"></span>
					<p>{{ link.title }}</p>
				</router-link>

				<router-link tag="a" class="nav-link closer apply-active apply-pulse" v-for="link in navSettings"
					:key="link.title" :to="{ path: link.path, params: {} }" @click="showMenu = false"
					v-scroll-to="link.scrollSettings" v-if="link.title === 'Apply' || link.title === 'Bewerben'">
					<span class="apply-icon" v-html="link.icon"></span>
					<p>{{ link.title }}</p>
				</router-link>

				<div class="lms-language-switch">
					<button @click="updateLanguage('DE')" :class="{ 'active': getLanguage === 'DE' }">DE</button>
					<button @click="updateLanguage('EN')" :class="{ 'active': getLanguage === 'EN' }">EN</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		navSettings: {},
		noMenu: {
			default: false
		}
	},
	name: 'navbar',
	data() {
		return {
			scrollPosition: null,
			showMenu: false,
		}
	},
	computed: {
		getLanguage() {
			return this.$store.state.language
		}
	},
	watch: {
		getLanguage(newLanguage) {
			localStorage.language = newLanguage;
		}
	},
	methods: {
		updateNav() {
			this.scrollPosition = window.scrollY
		},
		updateLanguage(language) {
			this.$store.commit('switchLanguage', language)
		}
	},
	mounted() {
		window.addEventListener('scroll', this.updateNav);
		var links = document.querySelectorAll('.closer')

		for (var link of links) {
			link.addEventListener('click', () => {
				this.showMenu = false
			})
		}
	}
}
</script>

<style lang="scss">
.apply-active {
	background: $lumos-pink !important;
	border-radius: 4px;

	p {
		color: #FFFFFF !important;
		font-weight: 500 !important;
		font-family: $avenir !important;
	}

	span {
		fill: #FFFFFF !important;
	}

	svg {
		opacity: 0.7 !important;
	}
}

.apply-active:hover svg {
	fill: #FFFFFF !important;
	opacity: 1 !important;
}

.lms-navbar {
	position: absolute;
	width: 100%;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	box-sizing: border-box;
	margin: 30px 0 0 0;
	z-index: 10005;

	.lms-language-switch {
		padding: 5px;
		margin: 0 0 0 15px;
		background-color: white;
	}

	.logo-container {
		margin: 0 auto 0 0;
	}

	.button-container {
		display: flex;
		transition: 0.5s ease-in-out;
	}

	&.landing-mode {
		.button-container {
			margin-right: 36%;
		}
	}

	&.sticky {
		position: fixed;
		margin: 0;
		border-bottom: 1px solid $border-grey;
		background: #FFFFFF;

		.button-container {
			margin-right: 0;
		}
	}

	.lms-content-frame {
		align-items: center;
	}

	img {
		height: 36px;
		cursor: pointer;
	}

	.nav-link {
		display: flex;
		align-items: center;
		font-family: $avenir;
		font-weight: 500;
		font-size: 14px;
		color: #000000;
		text-decoration: none;
		margin: 0 10px;
		border: none;
		outline: none;
		background: none;
		cursor: pointer;

		span {
			display: flex;
			align-items: center;
		}

		svg {
			opacity: 0.4;
			transform: scale(0.8);
			transition: 0.3s;
		}

		p {
			margin: 0 0 0 10px;
		}
	}

	.nav-link:hover svg {
		opacity: 1;
		fill: $lumos-pink-bright;
	}

	.lms-main-button {
		margin: 0 0 0 15px;
	}
}


/* - - - - - - - - - - - - - */
/* -  Mobile Optimization  - */
/* - - - - - - - - - - - - - */
.mobile {
	display: none !important;
}


@media screen and (max-width: 1000px) {

	.mobile {
		display: flex !important;
	}

	.lms-navbar {
		position: absolute;
		display: block;
		height: 70px;
		overflow-y: hidden;
		transition: 0.2s ease-in-out;
		top: 0;
		margin: 0;
		z-index: 10000;

		.lms-language-switch {
			margin: 0 0 30px 0;
		}

		.logo-container {
			display: flex;
			box-sizing: border-box;
			align-items: center;
			margin: 0 30px 0 30px;
			padding: 17px 30px;
			width: 100%;
			justify-content: space-between;
			transition: 0.2s ease-in-out;
		}

		.button-container {
			display: flex;
			box-sizing: border-box;
			flex-direction: column;
			width: 100%;
			margin: -1px 0 0 0;
			padding: 30px 30px 0 30px;
			transform: scaleY(0);
			transform-origin: top;
			border-bottom: 1px solid $border-grey;
			transition: 0.2s ease-in-out;
			align-items: flex-start;
		}

		&.open {
			height: 100vh;
			border-bottom: none !important;

			.logo-container {
				background: #FFFFFF;

				.nav-link {
					transform: rotate(90deg);
				}
			}

			.button-container {
				transform: scaleY(1);
				background: #FFFFFF;
				// margin: 0 -50px 0 -50px;
			}
		}

		&.landing-mode {
			.button-container {
				margin-right: 0;
			}
		}

		&.sticky-mobile {
			background: transparent;
			border-bottom: 1px solid $border-grey;

			.logo-container {
				background: #FFFFFF;
			}

			.button-container {
				margin-right: 0;
			}
		}

		.lms-content-frame {
			flex-direction: column;
			margin: 0 0 0 0;
			flex: 1;
			padding: 0 0;
		}

		img {
			height: 36px;
			margin: 0 auto 0 0;
			cursor: pointer;
		}

		.nav-link {
			display: flex;
			align-items: center;
			font-family: $avenir;
			font-weight: 500;
			font-size: 18px;
			color: #000000;
			text-decoration: none;
			margin: 15px 0;
			border: none;
			outline: none;
			background: none;
			cursor: pointer;
			transition: 0.3s ease-in-out;

			&.menu {
				margin: 0;
			}

			svg {
				opacity: 0.4;
				margin: 0 0 0 0;
				transform: scale(1.1);
				transition: 0.3s;
			}

			p {
				margin: 0 0 0 20px;
			}
		}

		.nav-link:hover svg {
			opacity: 1;
			fill: $lumos-pink-bright;
		}

		.lms-main-button {
			margin: 40px 0 30px 0;
		}
	}
}
</style>